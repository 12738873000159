import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCart } from "../contexts/CartContext";
import { useOrderContext } from "../contexts/OrderContext"; 
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";

const Checkout = () => {
  const { cart } = useCart(); // Fetch cart data from context
  const { createOrder } = useOrderContext(); // Get createOrder function from context
  const [selectedPayment, setSelectedPayment] = useState("payment1");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    country: "United States (US)",
    streetAddress: "",
    apartment: "",
    city: "",
    state: "California",
    postCode: "",
    phone: "",
    email: "",
    additionalInfo: "",
  });

  

const {state, dispatch} = useContext(UserContext);
  let userId ;
  if (!state.userInfo){
    userId = "nothing";
  }else{
     userId = state.userInfo.id;
  }
  // Handle payment method selection
  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.id);
  };

  // Calculate the total price dynamically from the cart
  const getTotal = () => {
    if (!cart || !cart.items) {
      return { subTotal: 0, total: 0 };
    }

    const subTotal = cart.items.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    const shippingCost = 0; // Add logic for shipping if needed
    const discount = 0; // Add logic for discounts if needed
    const total = subTotal + shippingCost - discount;

    return { subTotal, total };
  };

  const { subTotal, total } = getTotal(); // Get the subtotal and total

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission (e.g., order submission logic)
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const orderDetails = {
      userId,
      formData,
      cart,
      subTotal,
      total,
      shippingCost: 0, // Add shipping cost logic
      discount: 0, // Add discount logic
      paymentMethod: selectedPayment,
      status: "pending", // Set initial order status
    };
console.log(orderDetails)
    try {
      // Use createOrder function from OrderContext to place the order
      await createOrder(orderDetails)
    
      console.log("Order placed successfully!");
      // Optionally redirect to a success page
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  useEffect(() => {
    // Any additional logic to load initial data (e.g., from API or localStorage)
  }, []);

  return (
    <section className="checkout py-80">
      <div className="container container-lg">
        <div className="border border-gray-100 rounded-8 px-30 py-20 mb-40">
          <span>
            Have a coupon?{" "}
            <Link
              to="/cart"
              className="fw-semibold text-gray-900 hover-text-decoration-underline hover-text-main-600"
            >
              Click here to enter your code
            </Link>{" "}
          </span>
        </div>
        <div className="row">
          <div className="col-xl-9 col-lg-8">
            <form action="#" className="pe-xl-5" onSubmit={handleFormSubmit}>
              <div className="row gy-3">
                {/* Address and payment form fields go here */}
               
             
             
                  <div className="row gy-3">
                    {/* First Name */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="form-control"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* Last Name */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="form-control"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* Business Name */}
                    <div className="col-12">
                      <label htmlFor="businessName" className="form-label">
                        Business Name
                      </label>
                      <input
                        type="text"
                        id="businessName"
                        name="businessName"
                        className="form-control"
                        value={formData.businessName}
                        onChange={handleChange}
                      />
                    </div>

                    {/* Street Address */}
                    <div className="col-12">
                      <label htmlFor="streetAddress" className="form-label">
                        Street Address
                      </label>
                      <input
                        type="text"
                        id="streetAddress"
                        name="streetAddress"
                        className="form-control"
                        value={formData.streetAddress}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* Apartment */}
                    <div className="col-12">
                      <label htmlFor="apartment" className="form-label">
                        Apartment
                      </label>
                      <input
                        type="text"
                        id="apartment"
                        name="apartment"
                        className="form-control"
                        value={formData.apartment}
                        onChange={handleChange}
                      />
                    </div>

                    {/* City */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        className="form-control"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* State */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="state" className="form-label">
                        State
                      </label>
                      <select
                        id="state"
                        name="state"
                        className="form-select"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      >
                        <option value="California">California</option>
                        <option value="Texas">Texas</option>
                        <option value="New York">New York</option>
                        {/* Add more states here */}
                      </select>
                    </div>

                    {/* Post Code */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="postCode" className="form-label">
                        Post Code
                      </label>
                      <input
                        type="text"
                        id="postCode"
                        name="postCode"
                        className="form-control"
                        value={formData.postCode}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* Phone */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="phone" className="form-label">
                        Phone
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* Email */}
                    <div className="col-12">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    {/* Additional Info */}
                    <div className="col-12">
                      <label htmlFor="additionalInfo" className="form-label">
                        Additional Information
                      </label>
                      <textarea
                        id="additionalInfo"
                        name="additionalInfo"
                        className="form-control"
                        value={formData.additionalInfo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
              
              </div>
              <button
                type="submit"
                className="btn btn-main mt-40 py-18 w-100 rounded-8 mt-56"
              >
                Place Order
              </button>
            </form>

              
          </div>
          <div className="col-xl-3 col-lg-4">
            <div className="checkout-sidebar">
              <div className="bg-color-three rounded-8 p-24 text-center">
                <span className="text-gray-900 text-xl fw-semibold">
                  Your Orders
                </span>
              </div>
              <div className="border border-gray-100 rounded-8 px-24 py-40 mt-24">
                {/* Ensure cart is not null and has items */}
                {cart && cart.items && cart.items.length > 0 ? (
                  cart.items.map((product, index) => (
                    <div key={index} className="flex-between gap-24 mb-32">
                      <div className="flex-align gap-12">
                        <span className="text-gray-900 fw-normal text-md font-heading-two w-144">
                          {product.name}
                        </span>
                        <span className="text-gray-900 fw-normal text-md font-heading-two">
                          <i className="ph-bold ph-x" />
                        </span>
                        <span className="text-gray-900 fw-semibold text-md font-heading-two">
                          {product.quantity}
                        </span>
                      </div>
                      <span className="text-gray-900 fw-bold text-md font-heading-two">
                        ${product.price * product.quantity}
                      </span>
                    </div>
                  ))
                ) : (
                  <p>No items in your cart</p>
                )}
                <div className="border-top border-gray-100 pt-30 mt-30">
                  <div className="mb-32 flex-between gap-8">
                    <span className="text-gray-900 font-heading-two text-xl fw-semibold">
                      Subtotal
                    </span>
                    <span className="text-gray-900 font-heading-two text-md fw-bold">
                      ${subTotal}
                    </span>
                  </div>
                  <div className="mb-0 flex-between gap-8">
                    <span className="text-gray-900 font-heading-two text-xl fw-semibold">
                      Total
                    </span>
                    <span className="text-gray-900 font-heading-two text-md fw-bold">
                      ${total}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-32">
                <h5 className="text-lg">Payment Method</h5>
                <div className="payment-method">
                  {/* Payment method selection */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;


// import axios from 'axios';

// const handleFormSubmit = async (e) => {
//     e.preventDefault();

//     const orderData = {
//         user: "user_id_here",  // Replace with the actual user ID
//         cart: cart.items,  // Your cart items
//         user_info: formData,  // Form data for user information
//         subTotal: subTotal,
//         shippingCost: 0,  // Add logic for shipping if needed
//         discount: 0,  // Add discount logic if needed
//         total: total,
//         paymentMethod: selectedPayment,  // The selected payment method
//         cardInfo: { cardNumber: '1234-5678-1234-5678', expiry: '12/24' },  // Replace with actual card info if needed
//         shippingOption: 'Standard',  // Define the shipping option
//     };

//     try {
//         const response = await axios.post('http://localhost:5055/orders', orderData);
//         console.log('Order placed successfully:', response.data);
//         // Redirect or update UI upon success
//     } catch (error) {
//         console.error('Error placing order:', error);
//     }
// };




// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { useCart } from "../contexts/CartContext";

// const Checkout = () => {
//   const { cart } = useCart(); // Fetch cart data from context
//   const [selectedPayment, setSelectedPayment] = useState("payment1");
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     businessName: "",
//     country: "United States (US)",
//     streetAddress: "",
//     apartment: "",
//     city: "",
//     state: "California",
//     postCode: "",
//     phone: "",
//     email: "",
//     additionalInfo: "",
//   });

//   // Handle payment method selection
//   const handlePaymentChange = (event) => {
//     setSelectedPayment(event.target.id);
//   };

//   // Calculate the total price dynamically from the cart
//   const getTotal = () => {
//     if (!cart || !cart.items) {
//       return { subTotal: 0, total: 0 };
//     }

//     const subTotal = cart.items.reduce(
//       (acc, item) => acc + item.price * item.quantity,
//       0
//     );
//     const shippingCost = 0; // Add logic for shipping if needed
//     const discount = 0; // Add logic for discounts if needed
//     const total = subTotal + shippingCost - discount;

//     return { subTotal, total };
//   };

//   const { subTotal, total } = getTotal(); // Get the subtotal and total

//   // Handle form data changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   // Handle form submission (e.g., order submission logic)
//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     console.log(formData);
//     // Add order submission logic here (e.g., call an API to place the order)
//   };






  

//   useEffect(() => {
//     // Any additional logic to load initial data (e.g., from API or localStorage)
//   }, []);

//   return (
//     <section className="checkout py-80">
//       <div className="container container-lg">
//         <div className="border border-gray-100 rounded-8 px-30 py-20 mb-40">
//           <span>
//             Have a coupon?{" "}
//             <Link
//               to="/cart"
//               className="fw-semibold text-gray-900 hover-text-decoration-underline hover-text-main-600"
//             >
//               Click here to enter your code
//             </Link>{" "}
//           </span>
//         </div>
//         <div className="row">
//           <div className="col-xl-9 col-lg-8">
//             <form action="#" className="pe-xl-5" onSubmit={handleFormSubmit}>
//               <div className="row gy-3">
             
//                   <div className="row gy-3">
//                     {/* First Name */}
//                     <div className="col-12 col-md-6">
//                       <label htmlFor="firstName" className="form-label">
//                         First Name
//                       </label>
//                       <input
//                         type="text"
//                         id="firstName"
//                         name="firstName"
//                         className="form-control"
//                         value={formData.firstName}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     {/* Last Name */}
//                     <div className="col-12 col-md-6">
//                       <label htmlFor="lastName" className="form-label">
//                         Last Name
//                       </label>
//                       <input
//                         type="text"
//                         id="lastName"
//                         name="lastName"
//                         className="form-control"
//                         value={formData.lastName}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     {/* Business Name */}
//                     <div className="col-12">
//                       <label htmlFor="businessName" className="form-label">
//                         Business Name
//                       </label>
//                       <input
//                         type="text"
//                         id="businessName"
//                         name="businessName"
//                         className="form-control"
//                         value={formData.businessName}
//                         onChange={handleChange}
//                       />
//                     </div>

//                     {/* Street Address */}
//                     <div className="col-12">
//                       <label htmlFor="streetAddress" className="form-label">
//                         Street Address
//                       </label>
//                       <input
//                         type="text"
//                         id="streetAddress"
//                         name="streetAddress"
//                         className="form-control"
//                         value={formData.streetAddress}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     {/* Apartment */}
//                     <div className="col-12">
//                       <label htmlFor="apartment" className="form-label">
//                         Apartment
//                       </label>
//                       <input
//                         type="text"
//                         id="apartment"
//                         name="apartment"
//                         className="form-control"
//                         value={formData.apartment}
//                         onChange={handleChange}
//                       />
//                     </div>

//                     {/* City */}
//                     <div className="col-12 col-md-6">
//                       <label htmlFor="city" className="form-label">
//                         City
//                       </label>
//                       <input
//                         type="text"
//                         id="city"
//                         name="city"
//                         className="form-control"
//                         value={formData.city}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     {/* State */}
//                     <div className="col-12 col-md-6">
//                       <label htmlFor="state" className="form-label">
//                         State
//                       </label>
//                       <select
//                         id="state"
//                         name="state"
//                         className="form-select"
//                         value={formData.state}
//                         onChange={handleChange}
//                         required
//                       >
//                         <option value="California">California</option>
//                         <option value="Texas">Texas</option>
//                         <option value="New York">New York</option>
//                         {/* Add more states here */}
//                       </select>
//                     </div>

//                     {/* Post Code */}
//                     <div className="col-12 col-md-6">
//                       <label htmlFor="postCode" className="form-label">
//                         Post Code
//                       </label>
//                       <input
//                         type="text"
//                         id="postCode"
//                         name="postCode"
//                         className="form-control"
//                         value={formData.postCode}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     {/* Phone */}
//                     <div className="col-12 col-md-6">
//                       <label htmlFor="phone" className="form-label">
//                         Phone
//                       </label>
//                       <input
//                         type="tel"
//                         id="phone"
//                         name="phone"
//                         className="form-control"
//                         value={formData.phone}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     {/* Email */}
//                     <div className="col-12">
//                       <label htmlFor="email" className="form-label">
//                         Email
//                       </label>
//                       <input
//                         type="email"
//                         id="email"
//                         name="email"
//                         className="form-control"
//                         value={formData.email}
//                         onChange={handleChange}
//                         required
//                       />
//                     </div>

//                     {/* Additional Info */}
//                     <div className="col-12">
//                       <label htmlFor="additionalInfo" className="form-label">
//                         Additional Information
//                       </label>
//                       <textarea
//                         id="additionalInfo"
//                         name="additionalInfo"
//                         className="form-control"
//                         value={formData.additionalInfo}
//                         onChange={handleChange}
//                       />
//                     </div>
//                   </div>
              
//               </div>
//               <button
//                 type="submit"
//                 className="btn btn-main mt-40 py-18 w-100 rounded-8 mt-56"
//               >
//                 Place Order
//               </button>
//             </form>
//           </div>
//           <div className="col-xl-3 col-lg-4">
//             <div className="checkout-sidebar">
//               <div className="bg-color-three rounded-8 p-24 text-center">
//                 <span className="text-gray-900 text-xl fw-semibold">
//                   Your Orders
//                 </span>
//               </div>
//               <div className="border border-gray-100 rounded-8 px-24 py-40 mt-24">
//                 {/* Ensure cart is not null and has items */}
//                 {cart && cart.items && cart.items.length > 0 ? (
//                   cart.items.map((product, index) => (
//                     <div key={index} className="flex-between gap-24 mb-32">
//                       <div className="flex-align gap-12">
//                         <span className="text-gray-900 fw-normal text-md font-heading-two w-144">
//                           {product.name}
//                         </span>
//                         <span className="text-gray-900 fw-normal text-md font-heading-two">
//                           <i className="ph-bold ph-x" />
//                         </span>
//                         <span className="text-gray-900 fw-semibold text-md font-heading-two">
//                           {product.quantity}
//                         </span>
//                       </div>
//                       <span className="text-gray-900 fw-bold text-md font-heading-two">
//                         ${product.price * product.quantity}
//                       </span>
//                     </div>
//                   ))
//                 ) : (
//                   <p>No items in your cart</p>
//                 )}
//                 <div className="border-top border-gray-100 pt-30 mt-30">
//                   <div className="mb-32 flex-between gap-8">
//                     <span className="text-gray-900 font-heading-two text-xl fw-semibold">
//                       Subtotal
//                     </span>
//                     <span className="text-gray-900 font-heading-two text-md fw-bold">
//                       ${subTotal}
//                     </span>
//                   </div>
//                   <div className="mb-0 flex-between gap-8">
//                     <span className="text-gray-900 font-heading-two text-xl fw-semibold">
//                       Total
//                     </span>
//                     <span className="text-gray-900 font-heading-two text-md fw-bold">
//                       ${total}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//               <div className="mt-32">
//                 <h5 className="text-lg">Payment Method</h5>
//                 <div className="payment-method">
//                   {/* Payment method selection */}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Checkout; now correct it according to orderContext 

