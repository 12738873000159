import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Create a context for orders
const OrderContext = createContext();
const baseUrl = 'https://backend-2x37.onrender.com/api'

// Provide the context to components
export const OrderProvider = ({ children }) => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState({
        count: 0,
        amount: 0,
        recentOrders: [],
        bestSellerData: [],
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        totalOrders: 0,
    });

    // Fetch all orders
    const fetchOrders = async (page = 1, limit = 10) => {
        setLoading(true);
        setError(null);

        try {
            const queryString = new URLSearchParams({ page, limit }).toString();
            const response = await axios.get(`${baseUrl}/orders?${queryString}`);
            const { orders, totalDoc, limits, pages } = response.data;

            setOrders(orders);
            setPagination({
                currentPage: pages,
                totalPages: Math.ceil(totalDoc / limits),
                totalOrders: totalDoc,
            });
        } catch (err) {
            setError("Error fetching orders.");
        } finally {
            setLoading(false);
        }
    };

    // Fetch dashboard data
    const fetchDashboardData = async () => {
        try {
            const [countResponse, amountResponse, recentResponse, bestSellerResponse] = await Promise.all([
                axios.get("/api/orders/dashboard-count"),
                axios.get("/api/orders/dashboard-amount"),
                axios.get("/api/orders/dashboard-recent-order"),
                axios.get("/api/orders/best-seller/chart"),
            ]);

            setDashboardData({
                count: countResponse.data.count,
                amount: amountResponse.data.amount,
                recentOrders: recentResponse.data.orders,
                bestSellerData: bestSellerResponse.data.chart,
            });
        } catch (err) {
            setError("Error fetching dashboard data.");
        }
    };

    // Fetch a single order by ID
    const fetchOrderById = async (orderId) => {
        try {
            const response = await axios.get(`${baseUrl}/orders/${orderId}`);
            return response.data;
        } catch (err) {
            setError("Error fetching order by ID.");
        }
    };

    // Fetch all orders by a customer ID
    const fetchOrdersByCustomer = async (customerId) => {
        try {
            const response = await axios.get(`${baseUrl}/orders/customer/${customerId}`);
            console.log('fetchoredr',response.data)
            setOrders(response.data);
        } catch (err) {
            setError("Error fetching orders by customer.");
        }
    };

    // Update an order status
    const updateOrderStatus = async (orderId, status) => {
        try {
            await axios.put(`${baseUrl}/orders/${orderId}`, { status });
            fetchOrders(pagination.currentPage, 10); // Refresh orders
        } catch (err) {
            setError("Error updating order status.");
        }
    };

    // Delete an order
    const deleteOrder = async (orderId) => {
        try {
            await axios.delete(`${baseUrl}/orders/${orderId}`);
            fetchOrders(pagination.currentPage, 10); // Refresh orders
        } catch (err) {
            setError("Error deleting order.");
        }
    };

    // Handle form submission for creating an order
    const createOrder = async (orderDetails) => {
        const { cart, formData, subTotal, total, paymentMethod, userId,  } = orderDetails;
        console.log("Creating order with the following details:", formData);
    
        const orderData = {
            user: userId,  // Dynamically passed user ID from context or props
            cart: cart.items,  // Cart items from the cart context
            user_info:formData,  // User form data from the checkout form
            subTotal: subTotal,  // Dynamically calculated subtotal from the cart
            shippingCost: 0,  // Placeholder for shipping cost logic
            discount: 0,  // Placeholder for discount logic
            total: total,  // Final calculated total amount
            paymentMethod,  // Selected payment method (from form)
            cardInfo: { 
                cardNumber: '1234-5678-1234-5678', 
                expiry: '12/24' 
            },  // Placeholder card info, replace with actual info if needed
            shippingOption: 'Standard',  // Define shipping option logic if needed
        };
    
        try {
            // Post the order data to your API
            const response = await axios.post(`${baseUrl}/orders`, orderData);
            alert("Order placed successfully!");
            setTimeout(()=> {
                navigate('/')
                clearInterval();
            },1000);
        
            console.log('Order placed successfully:', response.data);
    
            // Update any necessary state or redirect the user to a confirmation page
            // If using OrderContext, you can update the order state here
        } catch (error) {
            console.error('Error placing order:', error);
            // Optionally set error state for user feedback
            setError("Error placing order.");
        }
    };
    

    // Context value to be provided to children
    const value = {
        orders,
        loading,
        error,
        pagination,
        dashboardData,
        fetchOrders,
        fetchDashboardData,
        fetchOrderById,
        fetchOrdersByCustomer,
        updateOrderStatus,
        deleteOrder,
        createOrder,  // Corrected function name here
    };

    return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

// Custom hook to use the Order context
export const useOrderContext = () => {
    const context = useContext(OrderContext);
    if (!context) {
        throw new Error("useOrderContext must be used within an OrderProvider");
    }
    return context;
};
