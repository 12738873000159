import React from 'react';
import { Link } from 'react-router-dom';
import QuantityControl from '../helper/QuantityControl';
import { useCart } from '../contexts/CartContext'; // Import the useCart hook

const CartSection = () => {
  const { cart, removeFromCart, updateQuantity } = useCart();
  console.log("inCart", cart);

  // Check if cart.items is an array before using map
  if (!Array.isArray(cart?.items)) {
    return <div>Loading...</div>; // Or handle the case where cart.items is not an array
  }

  const getTotal = () => {
    // Calculate total from cart.items if you want a dynamic total
    return cart.items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  return (
    <section className="cart py-80">
      <div className="container container-lg">
        <div className="row gy-4">
          <div className="col-xl-9 col-lg-8">
            <div className="cart-table border border-gray-100 rounded-8 px-40 py-48">
              <div className="overflow-x-auto scroll-sm scroll-sm-horizontal">
                <table className="table style-three">
                  <thead>
                    <tr>
                      <th className="h6 mb-0 text-lg fw-bold">Delete</th>
                      <th className="h6 mb-0 text-lg fw-bold">Product Name</th>
                      <th className="h6 mb-0 text-lg fw-bold">Price</th>
                      <th className="h6 mb-0 text-lg fw-bold">Quantity</th>
                      <th className="h6 mb-0 text-lg fw-bold">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.items.map((item) => (  
                      <tr key={item._id}>
                        <td>
                          <button
                            type="button"
                            className="remove-tr-btn flex-align gap-12 hover-text-danger-600"
                            onClick={() => removeFromCart(item.productId)}
                          >
                            <i className="ph ph-x-circle text-2xl d-flex" />
                            Remove
                          </button>
                        </td>
                        <td>
                          <div className="table-product d-flex align-items-center gap-24">
                            <Link
                              to={`/product-details/${item.id}`}
                              className="table-product__thumb border border-gray-100 rounded-8 flex-center"
                            >
                              <img src={item.image} alt={item.name} />
                            </Link>
                            <div className="table-product__content text-start">
                              <h6 className="title text-lg fw-semibold mb-8">
                                <Link
                                  to={`/product-details/${item.id}`}
                                  className="link text-line-2"
                                >
                                  {item.name}
                                </Link>
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-lg h6 mb-0 fw-semibold">₹{item.price}</span>
                        </td>
                        <td>
                          <QuantityControl
                            initialQuantity={item.quantity}
                            onChange={(quantity) => updateQuantity(item.productId, quantity)}
                          />
                        </td>
                        <td>
                          <span className="text-lg h6 mb-0 fw-semibold">₹{item.price * item.quantity}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
         
            </div>
          </div>
          <div className="col-xl-3 col-lg-4">
            <div className="cart-sidebar border border-gray-100 rounded-8 px-24 py-40">
              <h6 className="text-xl mb-32">Cart Totals</h6>
              <div className="bg-color-three rounded-8 p-24">
                <div className="mb-32 flex-between gap-8">
                  <span className="text-gray-900 font-heading-two">Subtotal</span>
                  <span className="text-gray-900 fw-semibold">₹{getTotal()}</span>
                </div>
                <div className="mb-32 flex-between gap-8">
                  <span className="text-gray-900 font-heading-two">Estimated Delivery</span>
                  <span className="text-gray-900 fw-semibold">Free</span>
                </div>
                <div className="mb-0 flex-between gap-8">
                  <span className="text-gray-900 font-heading-two">Estimated Taxes</span>
                  <span className="text-gray-900 fw-semibold">INR 10.00</span>
                </div>
              </div>
              <div className="bg-color-three rounded-8 p-24 mt-24">
                <div className="flex-between gap-8">
                  <span className="text-gray-900 text-xl fw-semibold">Total</span>
                  <span className="text-gray-900 text-xl fw-semibold">₹{getTotal() + 10}</span>
                </div>
              </div>
              <Link
                to="/checkout"
                className="btn btn-main mt-40 py-18 w-100 rounded-8"
              >
                Proceed to checkout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CartSection;
