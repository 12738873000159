import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create ProductContext
const ProductContext = createContext();
const baseUrl = 'https://backend-2x37.onrender.com/api'

// ProductContext provider component
export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [showingProducts, setShowingProducts] = useState([]);
  const [storeProducts, setStoreProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [singleProduct, setSingleProduct] =useState({})


  // Fetch all products
  useEffect(() => {
    axios.get(`${baseUrl}/products`)
      .then(response => {
        setProducts(response.data); // Update state with all products
        setLoading(false);
        
      })
      .catch(error => {
        console.error('Error fetching all products:', error);
        setLoading(false);
      });
  }, []);

  // Fetch showing products
  useEffect(() => {
    axios.get(`${baseUrl}/products/show`)
      .then(response => {
        setShowingProducts(response.data); // Update state with showing products
        setLoading(false);
        console.log("Fetched showing products:", response.data);
      })
      .catch(error => {
        console.error('Error fetching showing products:', error);
        setLoading(false);
      });
  }, []);

  // Fetch store products
  useEffect(() => {
    axios.get(`${baseUrl}/products/store`)
      .then(response => {
        setStoreProducts(response.data); // Update state with store products
        setLoading(false);
        console.log("Fetched store products:", response.data);
      })
      .catch(error => {
        console.error('Error fetching store products:', error);
        setLoading(false);
      });
  }, []);

  // Fetch a product by slug
  const getProductBySlug = (slug) => {
    axios.get(`${baseUrl}/products/product/${slug}`)
      .then(response => {
        console.log("Fetched product by slug:", response.data);
     
        return response.data;
      })
      .catch(error => {
        console.error('Error fetching product by slug:', error);
      });
  };
 
  const getProductById = (id) => {
    // Make sure the request updates the singleProduct state
    axios.post(`${baseUrl}/products/${id}`)
      .then(response => {
        setSingleProduct(response.data); // Store the fetched product in state
        console.log("Fetched product by ID:", response.data);
      })
      .catch(error => {
        console.error('Error fetching product by ID:', error);
      });
  };
  

  return (
    <ProductContext.Provider value={{
      products,
      showingProducts,
      storeProducts,
      loading,
      getProductBySlug,
      getProductById,
      singleProduct
    }}>
      {children}
    </ProductContext.Provider>
  );
};

// Custom hook to use product context
export const useProducts = () => {
  return React.useContext(ProductContext);
};
