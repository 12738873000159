import React from 'react';
import { Link } from 'react-router-dom';
import { useProducts } from '../contexts/ProductContext';

const RecommendedOne = () => {
  const { showingProducts, loading } = useProducts();
  console.log(showingProducts)
  if (loading) {
    return <div>Loading...</div>;
  }

  // Ensure `showingProducts` is an array and slice it to show only 12 products
  const productList = Array.isArray(showingProducts) ? showingProducts.slice(0, 12) : [];

  return (
    <section className="recommended">
      <div className="container container-lg">
        <div className="section-heading flex-between flex-wrap gap-16">
          <h5 className="mb-0">Recommended for you</h5>
          {/* Tabs */}
        </div>
        <div className="row g-12">
          {productList.map((product) => {
            // Safely extract data and provide fallbacks
            const title = product.title?.en || 'No Title Available';
            const imageUrl = product.image[0] || 'default.jpg'; // Use a placeholder image if missing
            const price = product.prices?.price || '0.00';
            const originalPrice = product.prices?.originalPrice || null;

            return (
              <div className="col-xxl-2 col-lg-3 col-sm-4 col-6" key={product.id}>
                <div className="product-card h-100 p-8 border border-gray-100 hover-border-main-600 rounded-16 position-relative transition-2">
                  <Link to={`/product-details/${product.id}`} className="product-card__thumb flex-center">
                    <img src={imageUrl} alt={title} />
                  </Link>
                  <div className="product-card__content p-sm-2">
                    <h6 className="title text-lg fw-semibold mt-12 mb-8">
                      <Link to={`/product-details/${product.id}`} className="link text-line-2">
                        {title}
                      </Link>
                    </h6>
                    <div className="product-card__price mb-8">
                      <span className="text-heading text-md fw-semibold">
                        ${price}
                        <span className="text-gray-500 fw-normal"> /Qty</span>
                      </span>
                      {originalPrice && (
                        <span className="text-gray-400 text-md fw-semibold text-decoration-line-through">
                          ${originalPrice}
                        </span>
                      )}
                    </div>
                    <Link
                      to="/cart"
                      className="product-card__cart btn bg-main-50 text-main-600 hover-bg-main-600 hover-text-white py-11 px-24 rounded-pill flex-align gap-8 mt-24 w-100 justify-content-center"
                    >
                      Add To Cart <i className="ph ph-shopping-cart" />
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default RecommendedOne;
