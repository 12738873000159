import FooterOne from "../components/FooterOne";
import HeaderOne from "../components/HeaderOne";
import UserDashboard from "../components/UserDashboard/UserDashboard";
import './UserDashboardPage.css';
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import { Outlet } from "react-router-dom";

const UserDashboardPage = () => {
  const { state } = useContext(UserContext);
  const name = state?.userInfo?.name || "User";

  return (
    <>
      <HeaderOne />
      <div className="Dashboard">
        <UserDashboard name={name} />
        <div className="outletData" >
          
          <Outlet />
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default UserDashboardPage;
