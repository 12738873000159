import React, { useEffect, useState } from "react";
import query from "jquery";
import { Link, NavLink } from "react-router-dom";
import { initCompiler } from "sass";
import { useData } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";
import { useContext } from "react";
import { useCart } from "../contexts/CartContext";
const HeaderOne = () => {
  const [scroll, setScroll] = useState(false);
  const { cart } = useCart();

  const { state, dispatch } = useContext(UserContext);

  const { data, loading } = useData();

  console.log("ceat", data);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset < 150) {
        setScroll(false);
      } else if (window.pageYOffset > 150) {
        setScroll(true);
      }
      return () => (window.onscroll = null);
    };
    const selectElement = query(".js-example-basic-single");
    selectElement.select2();

    return () => {
      if (selectElement.data("select2")) {
        selectElement.select2("destroy");
      }
    };
  }, []);

  // Set the default language
  const [selectedLanguage, setSelectedLanguage] = useState("Eng");
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  // Set the default currency
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
  };

  // Mobile menu support
  const [menuActive, setMenuActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const handleMenuClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleMenuToggle = () => {
    setMenuActive(!menuActive);
  };

  // Search control support
  const [activeSearch, setActiveSearch] = useState(false);
  const handleSearchToggle = () => {
    setActiveSearch(!activeSearch);
  };

  // category control support
  const [activeCategory, setActiveCategory] = useState(false);
  const handleCategoryToggle = () => {
    setActiveCategory(!activeCategory);
  };
  const [activeIndexCat, setActiveIndexCat] = useState(null);
  const handleCatClick = (index) => {
    setActiveIndexCat(activeIndexCat === index ? null : index);
  };

  let cartItems = 0;

  if (!cart?.items || !state.userInfo) {
    cartItems = 0;
  } else {
    cartItems = cart?.items.length;
  }

  return (
    <>
      <div className="overlay" />
      <div
        className={`side-overlay ${(menuActive || activeCategory) && "show"}`}
      />
      {/* ==================== Search Box Start Here ==================== */}
      <form action="#" className={`search-box ${activeSearch && "active"}`}>
        <button
          onClick={handleSearchToggle}
          type="button"
          className="search-box__close position-absolute inset-block-start-0 inset-inline-end-0 m-16 w-48 h-48 border border-gray-100 rounded-circle flex-center text-white hover-text-gray-800 hover-bg-white text-2xl transition-1"
        >
          <i className="ph ph-x" />
        </button>
        <div className="container">
          <div className="position-relative">
            <input
              type="text"
              className="form-control py-16 px-24 text-xl rounded-pill pe-64"
              placeholder="Search for a product or brand"
            />
            <button
              type="submit"
              className="w-48 h-48 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
            >
              <i className="ph ph-magnifying-glass" />
            </button>
          </div>
        </div>
      </form>
      {/* ==================== Search Box End Here ==================== */}
      {/* ==================== Mobile Menu Start Here ==================== */}
      <div
        className={`mobile-menu scroll-sm d-lg-none d-block ${
          menuActive && "active"
        }`}
      >
        <button
          onClick={() => {
            handleMenuToggle();
            setActiveIndex(null);
          }}
          type="button"
          className="close-button"
        >
          <i className="ph ph-x" />{" "}
        </button>
        <div className="mobile-menu__inner">
          <Link to="/" className="mobile-menu__logo">
            <img src="assets/images/logo/logo.png" alt="Logo" />
          </Link>
          <div className="mobile-menu__menu">
            {/* Nav Menu Start */}
            <ul className="nav-menu flex-align nav-menu--mobile">
              {/* Home Menu */}
              <li
                onClick={() => handleMenuClick(0)}
                className={`on-hover-item nav-menu__item  ${
                  activeIndex === 0 ? "d-block" : ""
                }`}
              >
                <Link to="/" className="nav-menu__link">
                  Home
                </Link>
              </li>

              {/* Shop Menu */}
              <li
                onClick={() => handleMenuClick(1)}
                className={`on-hover-item nav-menu__item has-submenu ${
                  activeIndex === 1 ? "d-block" : ""
                }`}
              >
                <Link to="#" className="nav-menu__link">
                  Shop
                </Link>
                <ul
                  className={`on-hover-dropdown common-dropdown nav-submenu scroll-sm ${
                    activeIndex === 1 ? "open" : ""
                  }`}
                >
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/shop"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                      onClick={() => setActiveIndex(null)}
                    >
                      {" "}
                      Shop
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/product-details"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                      onClick={() => setActiveIndex(null)}
                    >
                      {" "}
                      Shop Details
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/product-details-two"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                      onClick={() => setActiveIndex(null)}
                    >
                      {" "}
                      Shop Details Two
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Blog Menu */}
              <li
                onClick={() => handleMenuClick(3)}
                className={`on-hover-item nav-menu__item has-submenu ${
                  activeIndex === 3 ? "d-block" : ""
                }`}
              >
                <Link to="#" className="nav-menu__link">
                  Blog
                </Link>
                <ul
                  className={`on-hover-dropdown common-dropdown nav-submenu scroll-sm ${
                    activeIndex === 3 ? "open" : ""
                  }`}
                >
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/blog"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                      onClick={() => setActiveIndex(null)}
                    >
                      {" "}
                      Blog
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/blog-details"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                      onClick={() => setActiveIndex(null)}
                    >
                      {" "}
                      Blog Details
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Contact Us Menu */}
              <li className="nav-menu__item">
                <Link
                  to="/contact"
                  className="nav-menu__link"
                  onClick={() => setActiveIndex(null)}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            {/* Nav Menu End */}
          </div>
        </div>
      </div>
      {/* ==================== Mobile Menu End Here ==================== */}
      {/* ======================= Middle Top Start ========================= */}
      <div
        className="header-top flex-between"
        style={{ background: "#fa6800" }}
      >
        <div className="container container-lg">
          <div className="flex-between flex-wrap gap-8">
            <ul className="flex-align flex-wrap d-none d-md-flex">
              <li className="border-right-item">
                <Link
                  to="#"
                  className="text-white text-sm hover-text-decoration-underline"
                >
                  Become A Seller
                </Link>
              </li>
            </ul>

            <ul className="header-top__right flex-align flex-wrap">
              {state.userInfo && (
                <li className="border-right-item">
                  <Link
                    to="/UserDashboardPage"
                    className="text-white text-sm py-8 flex-align gap-6"
                  >
                    <span
                      className="icon text-md d-flex"
                      style={{ color: "white" }}
                    >
                      {" "}
                      <i className="ph ph-user-circle" />{" "}
                    </span>
                    <span
                      className="hover-text-decoration-underline"
                      style={{ color: "white", marginLeft: "-20px" }}
                    >
                      User Dashboard
                    </span>
                  </Link>
                </li>
              )}

              {!state.userInfo && (
                <li className="border-right-item">
                  <Link
                    to="/account"
                    className="text-white text-sm py-8 flex-align gap-6"
                  >
                    <span
                      className="icon text-md d-flex"
                      style={{ color: "white" }}
                    >
                      {" "}
                      <i className="ph ph-user-circle" />{" "}
                    </span>
                    <span
                      className="hover-text-decoration-underline"
                      style={{ color: "white", marginLeft: "-20px" }}
                    >
                      Login
                    </span>
                  </Link>
                </li>
              )}
              {state.userInfo && (
                <li className="border-right-item">
                  <Link
                    onClick={() => {
                      dispatch({ type: "USER_LOGOUT" });
                    }}
                    to="/account"
                    className="text-white text-sm py-8 flex-align gap-6"
                  >
                    <span
                      className="icon text-md d-flex"
                      style={{ color: "white" }}
                    >
                      <i className="ph ph-user-circle" />
                    </span>
                    <span
                      className="hover-text-decoration-underline"
                      style={{ color: "white", marginLeft: "-20px" }}
                    >
                      Logout
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* ======================= Middle Top End ========================= */}
      {/* ======================= Middle Header Start ========================= */}
      <header className=" bg-color-one border-bottom border-gray-100">
        <div className="container container-lg ">
          <nav className="d-flex header-inner align-items-center justify-content-around">
            {/* Logo Start */}
            <div className="logo">
              <Link to="/" className="link">
                <img src="assets/images/logo/logo.png" alt="Logo" />
              </Link>
            </div>

            {/* form location start */}
            <div className="search-form__wrapper position-relative">
              <input
                type="text"
                className="search-form__input common-input py-13 ps-16 pe-18 rounded-end-pill pe-44"
                placeholder="Search for a product or brand"
              />
              <button
                type="submit"
                className="w-32 h-32 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
              >
                <i className="ph ph-magnifying-glass" />
              </button>
            </div>
            {/* Header Middle Right start */}
            <div className="header-right flex-align d-lg-block d-none">
              <div className="flex-align flex-wrap gap-12">
                <button
                  type="button"
                  className="search-icon flex-align d-lg-none d-flex gap-4 item-hover"
                >
                  <span className="text-2xl text-gray-700 d-flex position-relative item-hover__text">
                    <i className="ph ph-magnifying-glass" />
                  </span>
                </button>
                {/* here was cart */}

                <Link to="/cart" className="flex-align gap-4 item-hover ">
                  <span className="text-2xl d-flex position-relative me-6 mt-6 item-hover__text">
                    <i className="ph ph-shopping-cart-simple" />
                    <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                      {cartItems}
                    </span>
                  </span>
                  <span className="text-md d-none d-lg-flex item-hover__text">
                    Cart
                  </span>
                </Link>
              </div>
            </div>
            {/* Header Middle Right End  */}
          </nav>
        </div>
      </header>
      {/* ======================= Middle Header End ========================= */}
      {/* ==================== Header Start Here ==================== */}
      <header
        className={`header bg-white border-bottom border-gray-100 ${
          scroll && "fixed-header"
        }`}
      >
        <div className="container container-lg" style={{background:"#299E60"}}>
          <nav className="header-inner d-flex justify-content-between gap-8">
            <div className="flex-align menu-category-wrapper">
              {/* Category Dropdown Start */}
              <div className="category on-hover-item">
                <button
                  onClick={handleCategoryToggle}
                  type="button"
                  className=" flex-align gap-8 fw-medium p-16 border-end border-start border-gray-100 text-heading"
                >
                  <span className="icon text-2xl d-xs-flex d-none">
                    <i className="ph ph-dots-nine" />
                  </span>
                  <p style={{color:"white", display:"flex", gap:".5rem", alignItems:"center"}}  > <span className="d-sm-flex d-none" style={{color:"white"}} >All</span> Categories</p>
                 
                  <span className="arrow-icon text-xl d-flex">
                    <i className="ph ph-caret-down" />
                  </span>
                </button>
                <div
                  className={`responsive-dropdown cat on-hover-dropdown common-dropdown nav-submenu p-0 submenus-submenu-wrapper ${
                    activeCategory && "active"
                  }`}
                >
                  <button
                    onClick={() => {
                      handleCategoryToggle();
                      setActiveIndexCat(null);
                    }}
                    type="button"
                    className="close-responsive-dropdown rounded-circle text-xl position-absolute inset-inline-end-0 inset-block-start-0 mt-4 me-8 d-lg-none d-flex"
                  >
                    {" "}
                    <i className="ph ph-x" />{" "}
                  </button>
                  {/* Logo Start */}
                  <div className="logo px-16 d-lg-none d-block">
                    <Link to="/" className="link">
                      <img src="assets/images/logo/logo.png" alt="Logo" />
                    </Link>
                  </div>
                  {/* Logo End */}
                  <ul className="scroll-sm p-0 py-8 w-300 max-h-400 overflow-y-auto">
                    <ul className="scroll-sm p-0 py-8 w-300 max-h-400 overflow-y-auto">
                      {loading ? (
                        <p>Loading categories...</p> // Show loading message when data is being fetched
                      ) : data && Array.isArray(data) && data.length > 0 ? (
                        data.map((category) => (
                          <li
                            key={category._id}
                            className="has-submenus-submenu"
                          >
                            <Link
                              to="#"
                              className="text-gray-500 text-15 py-12 px-16 flex-align gap-8 rounded-0"
                            >
                              <span className="text-xl d-flex">
                                <i className="ph ph-brandy" />
                              </span>
                              <span>{category.name.en}</span>
                              <span className="icon text-md d-flex ms-auto">
                                <i className="ph ph-caret-right" />
                              </span>
                            </Link>

                            {category.children &&
                              category.children.length > 0 && (
                                <div className="submenus-submenu py-16">
                                  <h6 className="text-lg px-16 submenus-submenu__title">
                                    {category.name.en}
                                  </h6>
                                  <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    {category.children.map((child) => (
                                      <li key={child._id}>
                                        <Link to="/shop">{child.name.en}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </li>
                        ))
                      ) : (
                        <p>No categories found.</p> // Fallback when no categories exist
                      )}
                    </ul>
                  </ul>
                </div>
              </div>
              {/* Category Dropdown End  */}
              {/* Menu Start  */}
              <div className="header-menu d-lg-block d-none" >
                {/* Nav Menu Start */}
                <ul className="nav-menu flex-align ">
                  <li className="on-hover-item nav-menu__item " >
                    <Link to="/" className="nav-menu__link" >
                      Home
                    </Link>
                  </li>
                  <li className="on-hover-item nav-menu__item ">
                    <Link to="/shop" className="nav-menu__link" >
                      Shop
                    </Link>
                  </li>

                  <li className="nav-menu__item item-hover__text">
                    <Link to="/blog" className="nav-menu__link" >
                      Blog
                    </Link>
                  </li>
                  <li className="nav-menu__item">
                    <NavLink
                      to="/contact"
                     
                      className={(navData) =>
                        navData.isActive
                          ? "nav-menu__link activePage"
                          : "nav-menu__link"
                      }
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
                {/* Nav Menu End */}
              </div>
              {/* Menu End  */}
            </div>
            {/* Header Right start */}
            <div className="header-right flex-align">
              <Link
                to="/tel:01234567890"
                className=" text-white p-12 h-100  flex-align gap-8 text-lg d-lg-flex d-none"
                style={{ background: "#299E60" }}
              >
                <div className="d-flex text-32">
                  <i className="ph ph-phone-call" />
                </div>
                01- 234 567 890
              </Link>
              <div className="me-16 d-lg-none d-block">
                <div className="flex-align flex-wrap gap-12">
                  <button
                    onClick={handleSearchToggle}
                    type="button"
                    className="search-icon flex-align d-lg-none d-flex gap-4 item-hover"
                  >
                    <span className="text-2xl text-gray-700 d-flex position-relative item-hover__text">
                      <i className="ph ph-magnifying-glass" />
                    </span>
                  </button>
                  <Link to="/cart" className="flex-align gap-4 item-hover">
                    <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                      <i className="ph ph-heart" />
                      <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                        2
                      </span>
                    </span>
                    <span className="text-md text-gray-500 item-hover__text d-none d-lg-flex">
                      Wishlist
                    </span>
                  </Link>
                  <Link to="/cart" className="flex-align gap-4 item-hover">
                    <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                      <i className="ph ph-shopping-cart-simple" />
                      <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                        2
                      </span>
                    </span>
                    <span className="text-md text-gray-500 item-hover__text d-none d-lg-flex">
                      Cart
                    </span>
                  </Link>
                </div>
              </div>
              <button
                onClick={handleMenuToggle}
                type="button"
                className="toggle-mobileMenu d-lg-none ms-3n text-gray-800 text-4xl d-flex"
              >
                {" "}
                <i className="ph ph-list" />{" "}
              </button>
            </div>
            {/* Header Right End  */}
          </nav>
        </div>
      </header>
      {/* ==================== Header End Here ==================== */}
    </>
  );
};

export default HeaderOne;
