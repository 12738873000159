import React, { useEffect, useState, useContext } from "react";
import "./UserOrderPage.css";
import { useOrderContext } from "../../contexts/OrderContext";
import { UserContext } from "../../contexts/UserContext";

const OrderHistory = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { orders, fetchOrdersByCustomer } = useOrderContext();
  const { state } = useContext(UserContext);

  useEffect(() => {
    if (state.userInfo?.id) {
      fetchOrdersByCustomer(state.userInfo.id);
    }
  }, [state.userInfo]);

  console.log("orders", orders);

  return (
    <div className="order-history-container fancy">
      <h2 className="title">Order History</h2>
      {orders?.length > 0 ? (
        <table className="order-history-table fancy">
          <thead>
            <tr>
              <th>#</th>
              <th>Order ID</th>
              <th>Date</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={order._id}>
                <td>{index + 1}</td>
                <td>{order.invoice}</td>
                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                <td>${order.total.toFixed(2)}</td>
                <td>
                  <span className={`status ${order.status.toLowerCase()}`}>
                    {order.status}
                  </span>
                </td>
                <td>
                  <button
                    className="details-button fancy"
                    onClick={() => setSelectedOrder(order)}
                  >
                    View Items
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-orders">No orders found.</div>
      )}

      {selectedOrder && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Order Details</h3>
            <ul>
              {selectedOrder.cart?.map((item, index) => (
                <li key={index} className="item">
                  <img src={item.image} alt={item.name} />
                  <div>
                    <p>{item.name}</p>
                    <p>
                      ${item.price.toFixed(2)} x {item.quantity}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
            <button
              className="close-button"
              onClick={() => setSelectedOrder(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
