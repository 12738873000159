import React from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import HeaderTwo from "../components/HeaderTwo";
import Breadcrumb from "../components/Breadcrumb";
import ShopSection from "../components/ShopSection";
import ShippingTwo from "../components/ShippingTwo";
import FooterTwo from "../components/FooterTwo";
import ColorInit from "../helper/ColorInit";
import ScrollToTop from "react-scroll-to-top";
import ShippingOne from "../components/ShippingOne";
import FooterOne from "../components/FooterOne";

const ShopPage = () => {

  return (
    <>
     
      <ColorInit color={true} />

      
      <ScrollToTop smooth color="#299E60" />

      
      <Preloader />

     
      <HeaderOne />
      {/* <HeaderTwo /> */}

      <Breadcrumb title={"Shop"} />

     
      <ShopSection />

      
      <ShippingOne />

      
      <FooterOne />


    </>
  );
};

export default ShopPage;
