import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getCountdown } from "../helper/Countdown";
import { useProducts } from "../contexts/ProductContext";
import { useParams } from "react-router-dom";
import { AlignRight, FileX } from "@phosphor-icons/react";
import ProductPDFViewer from "./PdfViewer";
import withPreventInspect from "../Preventer/withPreventInspect";
import { useCart } from "../contexts/CartContext";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player"; // For Lottie
import Swal from "sweetalert2";
const ProductDetailsOne = () => {
  const [timeLeft, setTimeLeft] = useState(getCountdown());
  const { singleProduct, getProductById } = useProducts();
  const { id } = useParams();
  const { addItemToCart } = useCart();
  const [quantity, setQuantity] = useState(1);
  const { state } = useContext(UserContext);
  console.log("user", state.userInfo);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      getProductById(id); // Fetch product by ID
    }
  }, []);

  const title = singleProduct?.title?.en;
  const description = singleProduct?.description?.en;

  const handleAddToCart = () => {
    console.log("ho gya");
    let price = 0;
   if(state.userInfo){
    try {
      if (
        singleProduct?.prices?.discount == 0 ||
        singleProduct?.prices?.discount < 1
      ) {
        price = singleProduct?.prices?.originalPrice;
      } else {
        price = singleProduct?.prices?.discount;
      }

      addItemToCart(singleProduct._id, quantity, price, title);
      Swal.fire({
        title: "Success!",
        html: `
                    <div id="lottie-animation"></div>
                    <p>Item added to your cart!</p>
                `,
        showConfirmButton: true,
        confirmButtonText: "OK",
        didOpen: () => {
          const lottieContainer = document.getElementById("lottie-animation");
          if (lottieContainer) {
            const player = document.createElement("lottie-player");
            player.setAttribute(
              "src",
              "https://assets2.lottiefiles.com/packages/lf20_zdtkdanx.json"
            );
            player.setAttribute("background", "transparent");
            player.setAttribute("speed", "1");
            player.style.width = "150px";
            player.style.height = "150px";
            player.setAttribute("autoplay", "true");
            lottieContainer.appendChild(player);
          }
        },
      });
    } catch (error) {
      console.error("Failed to add item to cart:", error);
      alert("Something went wrong. Please try again.");
    }
   }
   
  };

  console.log("title", title);

  console.log("single");
  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         setTimeLeft(getCountdown());
  //     }, 1000);

  //     return () => clearInterval(interval);
  // }, []);
  const productImages = [
    "assets/images/thumbs/product-details-thumb1.png",
    "assets/images/thumbs/product-details-thumb2.png",
    "assets/images/thumbs/product-details-thumb3.png",
    "assets/images/thumbs/product-details-thumb2.png",
  ];

  // increment & decrement

  const incrementQuantity = () => setQuantity(quantity + 1);
  const decrementQuantity = () =>
    setQuantity(quantity > 1 ? quantity - 1 : quantity);

  const [mainImage, setMainImage] = useState(productImages[0]);

  const settingsThumbs = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
  };
  return (
    <section className="product-details py-80">
      <div className="container container-lg">
        <div className="row gy-4">
          <div className="col-lg-9">
            <div className="row gy-4">
              <div className="col-xl-6">
                <div className="product-details__left">
                  <div className="product-details__thumb-slider border border-gray-100 rounded-16">
                    <div className="">
                      <div className="product-details__thumb flex-center h-100">
                        <img src={singleProduct.image} alt="Main Product" />
                      </div>
                    </div>
                  </div>
                  <div className="mt-24">
                    <div className="product-details__images-slider">
                      <Slider {...settingsThumbs}>
                        {productImages.map((image, index) => (
                          <div
                            className="center max-w-120 max-h-120 h-100 flex-center border border-gray-100 rounded-16 p-8"
                            key={index}
                            onClick={() => setMainImage(image)}
                          >
                            <img
                              className="thum"
                              src={image}
                              alt={`Thumbnail ${index}`}
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="product-details__content">
                  <h5 className="mb-12">{title}</h5>
                  <div className="flex-align flex-wrap gap-12">
                    <div className="flex-align gap-12 flex-wrap">
                      <div className="flex-align gap-8">
                        <span className="text-15 fw-medium text-warning-600 d-flex">
                          <i className="ph-fill ph-star" />
                        </span>
                        <span className="text-15 fw-medium text-warning-600 d-flex">
                          <i className="ph-fill ph-star" />
                        </span>
                        <span className="text-15 fw-medium text-warning-600 d-flex">
                          <i className="ph-fill ph-star" />
                        </span>
                        <span className="text-15 fw-medium text-warning-600 d-flex">
                          <i className="ph-fill ph-star" />
                        </span>
                        <span className="text-15 fw-medium text-warning-600 d-flex">
                          <i className="ph-fill ph-star" />
                        </span>
                      </div>
                      <span className="text-sm fw-medium text-neutral-600">
                        4.7 Star Rating
                      </span>
                      <span className="text-sm fw-medium text-gray-500">
                        (21,671)
                      </span>
                    </div>
                    <span className="text-sm fw-medium text-gray-500">|</span>
                    <span className="text-gray-900">
                      {" "}
                      <span className="text-gray-400">SKU:</span>EB4DRP{" "}
                    </span>
                  </div>
                  <span className="mt-32 pt-32 text-gray-700 border-top border-gray-100 d-block" />
                  <p className="text-gray-700">{description}</p>
                  <div className="mt-32 flex-align flex-wrap gap-32">
                    <div className="flex-align gap-8">
                      <h4 className="mb-0">
                        {singleProduct?.prices?.discount}
                      </h4>
                      <span className="text-md text-gray-500">
                        {singleProduct?.prices?.originalPrice}
                      </span>
                    </div>
                    <Link to="#" className="btn btn-main rounded-pill">
                      Order on What'sApp
                    </Link>
                  </div>

                  <div className="mb-24">
                    <div className="mt-32 flex-align gap-12 mb-16">
                      <span className="w-32 h-32 bg-white flex-center rounded-circle text-main-600 box-shadow-xl">
                        <i className="ph-fill ph-lightning" />
                      </span>
                      <h6 className="text-md mb-0 fw-bold text-gray-900">
                        Products are almost sold out
                      </h6>
                    </div>
                    <div
                      className="progress w-100 bg-gray-100 rounded-pill h-8"
                      role="progressbar"
                      aria-label="Basic example"
                      aria-valuenow={32}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      <div
                        className="progress-bar bg-main-two-600 rounded-pill"
                        style={{ width: "32%" }}
                      />
                    </div>
                    <span className="text-sm text-gray-700 mt-8">
                      Available only:45
                    </span>
                  </div>
                  <span className="text-gray-900 d-block mb-8">Quantity:</span>
                  <div className="flex-between gap-16 flex-wrap">
                    <div className="flex-align flex-wrap gap-16">
                      <div className="border border-gray-100 rounded-pill py-9 px-16 flex-align">
                        <button
                          onClick={decrementQuantity}
                          type="button"
                          className="quantity__minus p-4 text-gray-700 hover-text-main-600 flex-center"
                        >
                          <i className="ph ph-minus" />
                        </button>
                        <input
                          type="number"
                          className="quantity__input border-0 text-center w-32"
                          value={quantity}
                          readOnly
                        />
                        <button
                          onClick={incrementQuantity}
                          type="button"
                          className="quantity__plus p-4 text-gray-700 hover-text-main-600 flex-center"
                        >
                          <i className="ph ph-plus" />
                        </button>
                      </div>
                      <Link
                      
                        onClick={handleAddToCart}
                        to={state.userInfo ? "#" : "/account"}
                        className="btn btn-main rounded-pill flex-align d-inline-flex gap-8 px-48"
                      >
                        {" "}
                        <i className="ph ph-shopping-cart" /> Add To Cart
                      </Link>
                    </div>
                    
                    {/* <div className="flex-align gap-12">
                                            {/* <Link
                                                to="#"
                                                className="w-52 h-52 bg-main-50 text-main-600 text-xl hover-bg-main-600 hover-text-white flex-center rounded-circle"
                                            >
                                                <i className="ph ph-heart" />
                                            </Link> */}
                    {/* <Link
                                                to="#"
                                                className="w-52 h-52 bg-main-50 text-main-600 text-xl hover-bg-main-600 hover-text-white flex-center rounded-circle"
                                            >
                                                <i className="ph ph-shuffle" />
                                            </Link> 
                                           
                                        </div> */}
                  </div>
                  <span className="mt-32 pt-32 text-gray-700 border-top border-gray-100 d-block" />
                  {/* <div className="flex-between gap-16 p-12 border border-main-two-600 border-dashed rounded-8 mb-16">
                                        <div className="flex-align gap-12">
                                            <button
                                                type="button"
                                                className="w-18 h-18 flex-center border border-gray-900 text-xs rounded-circle hover-bg-gray-100"
                                            >
                                                <i className="ph ph-plus" />
                                            </button>
                                            <span className="text-gray-900 fw-medium text-xs">
                                                Mfr. coupon. $3.00 off 5
                                            </span>
                                        </div>
                                        <Link
                                            to="/cart"
                                            className="text-xs fw-semibold text-main-two-600 text-decoration-underline hover-text-main-two-700"
                                        >
                                            View Details
                                        </Link>
                                    </div>
                                    <ul className="list-inside ms-12">
                                        <li className="text-gray-900 text-sm mb-8">
                                            Buy 1, Get 1 FREE
                                        </li>
                                        <li className="text-gray-900 text-sm mb-0">
                                            Buy 1, Get 1 FREE
                                        </li>
                                    </ul> */}
                </div>
              </div>
            </div>
          </div>
          
          <div
            className="col-lg-3"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Link
              to="#"
              className="w-52 h-52 bg-main-50 text-main-600 text-xl hover-bg-main-600 hover-text-white flex-center rounded-circle"
            >
              <i className="ph ph-share-network" />
            </Link>
            <div className="product-details__sidebar border border-gray-100 rounded-16 overflow-hidden">
              <div className="p-24">
                <div className="flex-between bg-main-600 rounded-pill p-8">
                  <div className="flex-align gap-8">
                    <span className="w-44 h-44 bg-white rounded-circle flex-center text-2xl">
                      <i className="ph ph-storefront" />
                    </span>
                    <span className="text-white">by Marketpro</span>
                  </div>
                  <Link
                    to="/shop"
                    className="btn btn-white rounded-pill text-uppercase"
                  >
                    View Store
                  </Link>
                </div>
              </div>
              <div className="p-24 bg-color-one d-flex align-items-start gap-24 border-bottom border-gray-100">
                <span className="w-44 h-44 bg-white text-main-600 rounded-circle flex-center text-2xl flex-shrink-0">
                  <i className="ph-fill ph-truck" />
                </span>
                <div className="">
                  <h6 className="text-sm mb-8">Fast Delivery</h6>
                  <p className="text-gray-700">
                    Lightning-fast shipping, guaranteed.
                  </p>
                </div>
              </div>
              <div className="p-24 bg-color-one d-flex align-items-start gap-24 border-bottom border-gray-100">
                <span className="w-44 h-44 bg-white text-main-600 rounded-circle flex-center text-2xl flex-shrink-0">
                  <i className="ph-fill ph-arrow-u-up-left" />
                </span>
                <div className="">
                  <h6 className="text-sm mb-8">Free 90-day returns</h6>
                  <p className="text-gray-700">
                    Shop risk-free with easy returns.
                  </p>
                </div>
              </div>
              <div className="p-24 bg-color-one d-flex align-items-start gap-24 border-bottom border-gray-100">
                <span className="w-44 h-44 bg-white text-main-600 rounded-circle flex-center text-2xl flex-shrink-0">
                  <i className="ph-fill ph-check-circle" />
                </span>
                <div className="">
                  <h6 className="text-sm mb-8">
                    Pickup available at Shop location
                  </h6>
                  <p className="text-gray-700">Usually ready in 24 hours</p>
                </div>
              </div>
              <div className="p-24 bg-color-one d-flex align-items-start gap-24 border-bottom border-gray-100">
                <span className="w-44 h-44 bg-white text-main-600 rounded-circle flex-center text-2xl flex-shrink-0">
                  <i className="ph-fill ph-credit-card" />
                </span>
                <div className="">
                  <h6 className="text-sm mb-8">Payment</h6>
                  <p className="text-gray-700">
                    Payment upon receipt of goods, Payment by card in the
                    department, Google Pay, Online card.
                  </p>
                </div>
              </div>
              <div className="p-24 bg-color-one d-flex align-items-start gap-24 border-bottom border-gray-100">
                <span className="w-44 h-44 bg-white text-main-600 rounded-circle flex-center text-2xl flex-shrink-0">
                  <i className="ph-fill ph-check-circle" />
                </span>
                <div className="">
                  <h6 className="text-sm mb-8">Warranty</h6>
                  <p className="text-gray-700">
                    The Consumer Protection Act does not provide for the return
                    of this product of proper quality.
                  </p>
                </div>
              </div>
              <div className="p-24 bg-color-one d-flex align-items-start gap-24 border-bottom border-gray-100">
                <span className="w-44 h-44 bg-white text-main-600 rounded-circle flex-center text-2xl flex-shrink-0">
                  <i className="ph-fill ph-package" />
                </span>
                <div className="">
                  <h6 className="text-sm mb-8">Packaging</h6>
                  <p className="text-gray-700">
                    Research &amp; development value proposition graphical user
                    interface investor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="pt-80">
          <div className="product-dContent border rounded-24">
            <div className="product-dContent__header border-bottom border-gray-100 flex-between flex-wrap gap-16">
              <ul
                className="nav common-tab nav-pills mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-description-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-description"
                    type="button"
                    role="tab"
                    aria-controls="pills-description"
                    aria-selected="true"
                  >
                    Description
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-reviews-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-reviews"
                    type="button"
                    role="tab"
                    aria-controls="pills-reviews"
                    aria-selected="false"
                  >
                    Reviews
                  </button>
                </li>
              </ul>
              <Link
                to="#"
                className="btn bg-color-one rounded-16 flex-align gap-8 text-main-600 hover-bg-main-600 hover-text-white"
              >
                <img src="assets/images/icon/satisfaction-icon.png" alt="" />
                100% Satisfaction Guaranteed
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// export default withPreventInspect(ProductDetailsOne);

export default ProductDetailsOne;

 {/* PDF Section */}
// <div className="product-details__pdf-section border border-light rounded-3 mt-4 p-4">
// <ProductPDFViewer pdfUrl={`http://localhost:5055/${singleProduct.pdf}`}/>
// </div>