// DataContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const DataContext = createContext();
const baseUrl = 'https://backend-2x37.onrender.com/api'

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace with your backend API URL
    axios.get(`${baseUrl}/category/`)
      .then(response => {
        setData(response.data); // Update the state with the fetched data
        setLoading(false);        // Set loading to false once data is fetched
        // Log the data immediately after fetching
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);        // Handle the error and set loading to false
      });
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  // Log `data` after it has been updated (when `data` state changes)
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      
    }
  }, [data]); // This useEffect will run whenever `data` changes

  return (
    <DataContext.Provider value={{ data, loading }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return React.useContext(DataContext);
};
