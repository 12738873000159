import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext';
// Create a context for the Cart
const CartContext = createContext();
const baseUrl = 'https://backend-2x37.onrender.com/api'
// Create a provider to wrap around components where cart data is needed
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(null); // Store the cart data
  const [loading, setLoading] = useState(false); // Loading state for requests
  const [error, setError] = useState(null); // Error state to handle API errors
  const [products, setProducts] = useState([]); // Store products
  const [total, setTotal] = useState(0); // Store the total (subtotal)
  const {state, dispatch} = useContext(UserContext)
  let userId ;
  if (!state.userInfo){
    userId = "nothing";
    
  }else{
     userId = state.userInfo.id;
  }
 
  // console.log("cartData",cart)

  // Function to fetch the user's cart data
  const fetchCart = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/cart/${userId}`);
   
      setCart(response.data); // Set the fetched cart data
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch products on component mount
  useEffect(() => {
    setLoading(true);
    const fetchProducts = async () => {
      try {
        const response = await fetch("http://localhost:5055/products");
        const data = await response.json();
        setProducts(data); // Set fetched products data
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Fetch the cart subtotal
  const fetchTotal = async () => {
    try {
      const response = await axios.get(`${baseUrl}/cart/${userId}/subtotal`);
      setTotal(response.data.subTotal); // Set the subtotal in the state
    } catch (err) {
      setError(err.message);
    }
  };

  // Fetch cart data when the component mounts
  useEffect(() => {
    fetchCart();
    fetchTotal();
  }, [state.userInfo]); // Empty dependency array means it runs once after the initial render

  // Function to add an item to the cart
  const addItemToCart = async (productId, quantity, price, name) => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/cart/${userId}/add`, {productId, quantity, price,name });
      setCart(response.data); // Update cart data after item is added
      fetchTotal(); // Recalculate the total
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to update the quantity of an item in the cart
  const updateQuantity = async (productId, quantity) => {
    setLoading(true);
    console.log("car",productId)
    try {
      const response = await axios.put(`${baseUrl}/cart/${userId}/update/${productId}`, { quantity });
      setCart(response.data); // Update cart data after quantity update
      fetchTotal(); // Recalculate the total
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to remove an item from the cart
  const removeFromCart = async (productId) => {
    console.log("itemId", productId);
    setLoading(true);
    try {
      const response = await axios.delete(`${baseUrl}/cart/${userId}/remove/${productId}`);
      setCart(response.data); // Update cart data after item removal
      fetchTotal(); // Recalculate the total
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        products,
        loading,
        error,
        total, // Provide total in context
        addItemToCart,
        updateQuantity,
        removeFromCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Create a custom hook to use cart data and actions
export const useCart = () => useContext(CartContext); 
