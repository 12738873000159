import React from "react";
import "./UserDashboard.css";
import { Link } from "react-router-dom";

const UserDashboard = ({name}) => {
  return (
   
   
     <div className="sidebar">
      <div className="logo">
        <h1>{`Hello ${name} !`}</h1>
      </div>
      <ul className="menu">
        <Link  to='' className="menu-item">
          <i className="icon fas fa-home"></i>
          <span>My Profile</span>
        </Link>
        <Link to="#" className="menu-item">
          <i className="icon fas fa-cogs"></i>
          <span>My eBooks</span>
        </Link>
        <Link to='userOrderPage' className="menu-item">
          <i className="icon fas fa-user"></i>
          <span>Order History</span>
        </Link>
       
        <Link to="#" className="menu-item">
          <i className="icon fas fa-sign-out-alt"></i>
          <span>Logout</span>
        </Link>
      </ul>
    </div>
   
   
  );
};

export default UserDashboard;


  // <div className='user-dashboard'>
    //   <UserCard name="My Profile" description="Thank you for visiting!" />
    //   <UserCard name="My eBook" description="Manage your account settings here." />
    //   <UserCard name="My Orders" description="Check your latest notifications." path='userOrderPage' />
    //   <UserCard name="My History" description="Find answers to your questions." />
    //   <UserCard name="Contact Us" description="Check your latest notifications." path='/contact'/>
    //   <UserCard name="Help" description="Find answers to your questions." />
    // </div>
  
