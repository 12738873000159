import React, { useState, useContext, useEffect } from "react";
import "./userProfile.css";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
const baseUrl = 'https://backend-2x37.onrender.com/api'

const UserProfile = () => {
  const { state } = useContext(UserContext);
  const id = state.userInfo.id;
  const [userInfo, setUserInfo] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    email: "",
    phoneNumber: "",
    gender: "Not specified",
  });

  // Fetch user details
  const getUser = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/${id}`);
      setUserInfo(response.data);
      console.log(response.data)
    } catch (error) {
      console.error(error);
      alert("Failed to get profile. Please try again.");
    }
  };

  // Update formData when userInfo changes
  useEffect(() => {
    setFormData({
      name: userInfo.name || "",
      username: userInfo.username || "",
      email: userInfo.email || "",
      phoneNumber: userInfo.phoneNumber || "",
      gender: userInfo.gender || "Not specified",
    });
  }, [userInfo]);

  // Update user details
  const updateUser = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/user/update/${id}`,
        formData
      );
      console.log(response.data);
      alert("Profile updated successfully!");
    } catch (error) {
      console.error(error);
      alert("Failed to update profile. Please try again.");
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="profile-form-container">
      <div className="form-section">
        <form>
          <h3>Personal Details</h3>

          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="username">User Name</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              readOnly
              style={{ backgroundColor: "#f0f0f0" }} // Gray out read-only fields
            />
          </div>

          <div className="form-group">
            <label htmlFor="gender">Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <option>Not specified</option>
              <option>Male</option>
              <option>Female</option>
              <option>Other</option>
            </select>
          </div>
        </form>
        <button className="update-btn" onClick={updateUser}>
          Update Profile
        </button>
      </div>

      {/* profile picture */}

      {/* <div className="profile-picture-section">
        <h3>My profile picture</h3>
        <p>Add a photo of you to be easily recognized</p>
        <img
          src={userInfo.profilePicture || "https://via.placeholder.com/150"}
          alt="Profile"
          className="profile-picture"
        />
        <button className="profile-history-btn">Profile History</button>
      </div> */}
    </div>
  );
};

export default UserProfile;
