import { BrowserRouter, Routes, Route } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import PhosphorIconInit from "./helper/PhosphorIconInit";
import HomePageOne from "./pages/HomePageOne";
import ShopPage from "./pages/ShopPage";
import ProductDetailsPageOne from "./pages/ProductDetailsPageOne";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import AccountPage from "./pages/AccountPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ContactPage from "./pages/ContactPage";
import UserDashboardPage from "./pages/UserDashboardPage";
import UserOrderPage from "./components/UserOrder/UserOrderPage";
// Import Context Providers
import { DataProvider } from "./contexts/DataContext";
import { ProductProvider } from "./contexts/ProductContext";
import { CartProvider } from "./contexts/CartContext";
import { UserProvider } from "./contexts/UserContext"; // Import UserProvider
import { OrderProvider } from "./contexts/OrderContext"; // Import OrderProvider
import UserProfile from "./components/UserDashboard/MyProfile";

function App() {
  return (
    <BrowserRouter>
      {/* Wrap all providers at the top level */}
      <UserProvider> {/* Add UserProvider here */}
        <DataProvider>
          <ProductProvider>
            <CartProvider>
              <OrderProvider> {/* Wrap with OrderProvider */}
                <RouteScrollToTop />
                <PhosphorIconInit />

                <Routes>
                  {/* Define your routes */}
                  <Route path="/" element={<HomePageOne />} />
                  <Route path="/shop" element={<ShopPage />} />
                  <Route path="/product-details/:id" element={<ProductDetailsPageOne />} />
                  <Route path="/cart" element={<CartPage />} />
                  <Route path="/checkout" element={<CheckoutPage />} />
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="/blog" element={<BlogPage />} />
                  <Route path="/blog-details" element={<BlogDetailsPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/UserDashboardPage" element={<UserDashboardPage />} >
                    <Route path="userOrderPage" element={<UserOrderPage />} />
                    <Route index element={<UserProfile />} />
                  </Route>

                </Routes>
              </OrderProvider>
            </CartProvider>
          </ProductProvider>
        </DataProvider>
      </UserProvider>

    </BrowserRouter >
  );
}

export default App;
