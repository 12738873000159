import React from "react";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
// import HeaderTwo from "../components/HeaderTwo";
import BannerOne from "../components/BannerOne";
import FeatureOne from "../components/FeatureOne";
import PromotionalOne from "../components/PromotionalOne";
import FlashSalesOne from "../components/FlashSalesOne";
import ProductListOne from "../components/ProductListOne";
import OfferOne from "../components/OfferOne";
import RecommendedOne from "../components/RecommendedOne";
import HotDealsOne from "../components/HotDealsOne";
import TopVendorsOne from "../components/TopVendorsOne";
import BestSellsOne from "../components/BestSellsOne";
import DeliveryOne from "../components/DeliveryOne";
import OrganicOne from "../components/OrganicOne";
import ShortProductOne from "../components/ShortProductOne";
import BrandOne from "../components/BrandOne";
import NewArrivalOne from "../components/NewArrivalOne";
import ShippingOne from "../components/ShippingOne";
import NewsletterOne from "../components/NewsletterOne";
import FooterOne from "../components/FooterOne";
import BottomFooter from "../components/BottomFooter";
import ScrollToTop from "react-scroll-to-top";
import ColorInit from "../helper/ColorInit";
const HomePageOne = () => {

  return (

    <>
     
      <Preloader />

    
      <ScrollToTop smooth color="#299E60" />

      
      <ColorInit color={false} />

      
      <HeaderOne/>

     
      <BannerOne />

      
      <FeatureOne />

      
      <PromotionalOne />

    
      <FlashSalesOne />

      
      <ProductListOne />

      
      <OfferOne />

      
      <RecommendedOne />

      
      <HotDealsOne />

      
      <TopVendorsOne />

    
      <BestSellsOne />

     
      <DeliveryOne />

     
      <OrganicOne />

      
      <ShortProductOne />

      
      <BrandOne />

      
      <NewArrivalOne />

    
      <ShippingOne />

     
      <NewsletterOne />

     
      <FooterOne />

      
      <BottomFooter />


    </>
  );
};

export default HomePageOne;
