import React, { useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


const Account = () => {
  const { dispatch } = useContext(UserContext);
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
  const [registerInfo, setRegisterInfo] = useState({ name: '', username: '', email: '', password: '' });
  const [loginError, setLoginError] = useState(""); // State to hold error message for login
  const navigate = useNavigate(); 
  const baseUrl = 'https://backend-2x37.onrender.com/api'

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError(""); // Reset error message on each login attempt
    try {
      const { data } = await axios.post(`${baseUrl}/user/login`, loginInfo);

      dispatch({ type: 'USER_LOGIN', payload: data.user });
      if (data.user) {
        // Redirect if login is successful
       navigate('/')
      }
    } catch (error) {
      // Display error message if login fails
      if (error.response && error.response.data && error.response.data.message) {
        setLoginError(error.response.data.message); // Set error message to display
      } else {
        setLoginError("An error occurred. Please try again."); // Generic error message
      }
      console.error('Login failed:', error.response ? error.response.data.message : error.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseUrl}/user/register`, registerInfo);
      alert('Registration successful! Please login.');
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  return (
    <section className="account py-80">
      <div className="container container-lg">
        <div className="row gy-4">
          {/* Login Card */}
          <div className="col-xl-6 pe-xl-5">
            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
              <h6 className="text-xl mb-32">Login</h6>
              <form onSubmit={handleLogin}>
                <div className="mb-24">
                  <label htmlFor="email" className="text-neutral-900 text-lg mb-8 fw-medium">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="common-input"
                    id="email"
                    placeholder="Email"
                    value={loginInfo.email}
                    onChange={(e) => setLoginInfo({ ...loginInfo, email: e.target.value })}
                  />
                </div>
                <div className="mb-24">
                  <label htmlFor="password" className="text-neutral-900 text-lg mb-8 fw-medium">
                    Password
                  </label>
                  <input
                    type="password"
                    className="common-input"
                    id="password"
                    placeholder="Enter Password"
                    value={loginInfo.password}
                    onChange={(e) => setLoginInfo({ ...loginInfo, password: e.target.value })}
                  />
                </div>
                {loginError && (
                  <div className="mb-24 text-danger">
                    <strong>{loginError}</strong>
                  </div>
                )}
                <div className="mb-24 mt-48">
                  <button type="submit" className="btn btn-main py-18 px-40 w-100">
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Register Card */}
          <div className="col-xl-6">
            <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40">
              <h6 className="text-xl mb-32">Register</h6>
              <form onSubmit={handleRegister}>
                <div className="mb-24">
                  <label htmlFor="name" className="text-neutral-900 text-lg mb-8 fw-medium">
                    Full Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="common-input"
                    id="name"
                    placeholder="Full Name"
                    value={registerInfo.name}
                    onChange={(e) => setRegisterInfo({ ...registerInfo, name: e.target.value })}
                  />
                </div>
                <div className="mb-24">
                  <label htmlFor="username" className="text-neutral-900 text-lg mb-8 fw-medium">
                    Username <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="common-input"
                    id="username"
                    placeholder="Username"
                    value={registerInfo.username}
                    onChange={(e) => setRegisterInfo({ ...registerInfo, username: e.target.value })}
                  />
                </div>
                <div className="mb-24">
                  <label htmlFor="email" className="text-neutral-900 text-lg mb-8 fw-medium">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="common-input"
                    id="email"
                    placeholder="Email"
                    value={registerInfo.email}
                    onChange={(e) => setRegisterInfo({ ...registerInfo, email: e.target.value })}
                  />
                </div>
                <div className="mb-24">
                  <label htmlFor="password" className="text-neutral-900 text-lg mb-8 fw-medium">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="common-input"
                    id="password"
                    placeholder="Password"
                    value={registerInfo.password}
                    onChange={(e) => setRegisterInfo({ ...registerInfo, password: e.target.value })}
                  />
                </div>
                <div className="mt-48">
                  <button type="submit" className="btn btn-main py-18 px-40 w-100">
                    Register
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
