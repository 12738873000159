import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Custom arrow components for Slick slider
function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className={`${className} slick-next slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1`}
        >
            <i className="ph ph-caret-right" />
        </button>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className={`${className} slick-prev slick-arrow flex-center rounded-circle bg-white text-xl hover-bg-main-600 hover-text-white transition-1`}
        >
            <i className="ph ph-caret-left" />
        </button>
    );
}

const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1500, // Slide speed
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set autoplay speed (in milliseconds)
};

const BannerOne = () => {
    const imageStyle = {
        width: "100%",
        height: "auto",  // This will ensure the image maintains its aspect ratio
        objectFit: "cover",  // Makes sure the image covers the container without distortion
        // Optional: rounded corners
    };

    return (
        <div className="">
            <div className=" ">
                <div className="overflow-hidden position-relative arrow-center">
                    {/* The Slider */}
                    <Slider {...settings}>
                        <div className="banner-slider__item">
                            <div className="banner-slider__inner position-relative" style={{ height: "600px" }}>
                                <img
                                    src="assets/images/thumbs/b1.webp" // Replace with your image source
                                    alt="Banner Image 1"
                                    style={imageStyle}  // Inline styles applied here
                                />
                            </div>
                        </div>
                        <div className="banner-slider__item">
                            <div className="banner-slider__inner position-relative">
                                <img
                                    src="assets/images/thumbs/b2.webp" // Replace with your image source
                                    alt="Banner Image 2"
                                    style={imageStyle}  // Inline styles applied here
                                />
                            </div>
                        </div>
                        <div className="banner-slider__item">
                            <div className="banner-slider__inner position-relative">
                                <img
                                    src="assets/images/thumbs/b3.webp"
                                    alt="Banner Image 3"
                                    style={imageStyle}  // Inline styles applied here
                                />
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default BannerOne;
