import React, { useContext } from "react";
import { Navigate } from "react-router-dom"; // Import Navigate
import Preloader from "../helper/Preloader";
import ColorInit from "../helper/ColorInit";

import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import BottomFooter from "../components/BottomFooter";
import CartSection from "../components/CartSection";
import ShippingOne from "../components/ShippingOne";
import ScrollToTop from "react-scroll-to-top";
import { UserContext } from "../contexts/UserContext";
import HeaderOne from "../components/HeaderOne";

const CartPage = () => {
  const { state } = useContext(UserContext);
  
  // Check if the userInfo is available
  if (!state.userInfo) {
    // If not, redirect to the AccountPage
    return <Navigate to="/account" />;
  }

  return (
    <>
      {/* ColorInit */}
      <ColorInit color={true} />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#FA6400" />

      {/* Preloader */}
      <Preloader />

      {/* HeaderTwo */}
      <HeaderOne category={true} />

      {/* Breadcrumb */}
      <Breadcrumb title={"Cart"} />

      {/* CartSection */}
      <CartSection />

      {/* ShippingOne */}
      <ShippingOne />

      {/* FooterTwo */}
      <FooterOne />

      {/* BottomFooter */}
      <BottomFooter />
    </>
  );
};

export default CartPage;
